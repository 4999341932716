import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        if (darkMode) {
            document.documentElement.classList.remove('dark');
        } else {
            document.documentElement.classList.add('dark');
        }
    };

    return (
        <header className="bg-white dark:bg-gray-800 shadow-md font-serif">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <div className="text-2xl text-gray-900 dark:text-white">Seemarise</div>
                <nav className="space-x-4">
                    <Link to="/"  className="text-gray-900 dark:text-white hover:underline">Home</Link> 
                    <Link to="/projects" className="text-gray-900 dark:text-white hover:underline">Project</Link> 
                    <Link to="/contact" className="text-gray-900 dark:text-white hover:underline">Contact</Link> 
                    <Link to="/team" className="text-gray-900 dark:text-white hover:underline">Team</Link> 
                    <Link to="/blog" className="text-gray-900 dark:text-white hover:underline">Blog</Link> 
                </nav>
                <button
                    onClick={toggleDarkMode}
                    className="bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white px-3 py-2 rounded"
                >
                    {darkMode ? 'Light Mode' : 'Dark Mode'}
                </button>
            </div>
        </header>
    );
};

export default Header;