import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = ({ title, excerpt, imageUrl }) => (
  <div className="bg-white shadow-md rounded-lg overflow-hidden">
    <img src={imageUrl} alt={title} className="w-full h-60 object-cover" />
    <div className="p-4">
      <h3 className="text-xl mb-4">{title}</h3>
      <p className="text-gray-600 mb-4">{excerpt}</p>
      <Link to="/BlogBody" className="text-blue-600 hover:underline">Read more</Link>
    </div>
  </div>
);

const Blog = () => {
  const blogPosts = [
    {
      title: "The Future of AI in Product Development",
      excerpt: "Exploring how artificial intelligence is revolutionizing the way we create and improve products.",
      imageUrl: "/image/Anubhav.jpg"
    },
    {
      title: "Sustainable Practices in Modern Industries",
      excerpt: "A look at how companies are adopting eco-friendly approaches to manufacturing and operations.",
      imageUrl: "/image/Lucky.jpg"
    },
    {
      title: "The Rise of Remote Work in Tech Companies",
      excerpt: "Analyzing the shift towards distributed teams and its impact on productivity and company culture.",
      imageUrl: "/image/harshit.jpeg"
    }
  ];

  return (
    <div className="container font-serif mx-auto mt-8 px-4">
      <h2 className="text-3xl  mb-4">Seemarise Blog :</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {blogPosts.map((post, index) => (
          <BlogPost key={index} {...post} />
        ))}
      </div>
    </div>
  );
};

export default Blog;