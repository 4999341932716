import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-slate-800 text-gray-300 p-6 mt-2 font-serif">
            <div className="container mx-auto text-center">
                <p className="mb-2">&copy; {new Date().getFullYear()} Seemarise. All rights reserved.</p>
                <nav className="footer-nav space-x-6">
                    <a href="/about" className="hover:text-white transition-colors">About Us</a>
                    <a href="/contact" className="hover:text-white transition-colors">Contact</a>
                    <a href="/privacy" className="hover:text-white transition-colors">Privacy Policy</a>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;
