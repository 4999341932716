import React from 'react';
import { ArrowRight, Newspaper, Briefcase, Zap } from 'lucide-react';

const Home = () => (
  <div className="min-h-screen bg-gradient-to-br from-slate-50 to-slate-200 font-serif">
    <main className="container mx-auto py-12 px-4">
      <section className="text-center mt-28 mb-36">
        <h1 className="text-4xl mb-4">Welcome to Seemarise</h1>
        <p className="text-xl max-w-2xl mx-auto">
          Seemarise is a parent company of innovative product-based companies, driving growth and excellence across various industries.
        </p>
      </section>

      <section className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        <div className="bg-white p-8 rounded-lg shadow-lg border border-slate-200">
          <h2 className="text-2xl mb-4 flex items-center">
            <Briefcase className="mr-2" /> Our Mission
          </h2>
          <p className="">
            To foster innovation and support the growth of our subsidiary companies, creating value through strategic partnerships and cutting-edge products.
          </p>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-lg border border-slate-200">
          <h2 className="text-2xl mb-4 flex items-center">
            <Newspaper className="mr-2" /> Latest News
          </h2>
          <ul className="space-y-2">
            <li className="flex items-center">
              <ArrowRight className="mr-2 cursor-pointer" size={16} />
              Seemarise acquires new tech startup
            </li>
            <li className="flex items-center">
              <ArrowRight className="mr-2 cursor-pointer" size={16} />
              Q2 financial results exceed expectations
            </li>
            <li className="flex items-center">
              <ArrowRight className="mr-2 cursor-pointer" size={16} />
              New sustainable energy project launched
            </li>
          </ul>
        </div>
      </section>

      <section className="bg-slate-900 text-white p-12 rounded-lg shadow-xl mb-16">
        <h2 className="text-3xl text-center mb-9">Our Innovative Approach</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex flex-col items-center text-center">
            <Zap size={48} className="mb-4" />
            <h3 className="text-xl mb-2">Cutting-edge Technology</h3>
            <p className="text-slate-300">We leverage the latest technologies to drive innovation across our portfolio.</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <Briefcase size={48} className="mb-4" />
            <h3 className="text-xl mb-2">Strategic Partnerships</h3>
            <p className="text-slate-300">We form powerful alliances to expand our reach and capabilities.</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <Zap size={48} className="mb-4" />
            <h3 className="text-xl mb-2">Sustainable Growth</h3>
            <p className="text-slate-300">Our focus on long-term success ensures steady growth for our companies.</p>
          </div>
        </div>
      </section>

      <section className="text-center">
        <h2 className="text-2xl mb-4">Ready to Join Our Journey?</h2>
        <p className="text-xl mb-6">Discover how Seemarise can help your business thrive.</p>
        <button className="bg-slate-900 text-white px-8 py-3 rounded-full text-lg transition-colors">
          Get in Touch
        </button>
      </section>
    </main>
  </div>
);

export default Home;