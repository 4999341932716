import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Project from './pages/Project';
import Team from './pages/Team';
import Contact from './pages/Contact';
import BlogPage from './pages/Blog Body';
const App = () => {
  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/blog" element={<Blog />} /> 
          <Route path="/projects" element={<Project />} />
          <Route path="/team" element={<Team />} /> 
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogbody" element={<BlogPage />} />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </MainLayout>
    </Router>
  );
};
export default App;

