import React from 'react';

const BlogPage = () => {
    return (
        <div className="bg-gray-100 min-h-screen py-10 font-serif">
            <div className="container mx-auto px-4 lg:px-8">
                {/* Blog Header */}
                <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                    <img
                        src="https://via.placeholder.com/1200x400"
                        alt="Blog Header"
                        className="w-full h-64 object-cover"
                    />
                    <div className="p-6 lg:p-10">
                        <h1 className="text-4xl font-bold mb-4">
                            Blog Page with React
                        </h1>
                        <p className="text-lg mb-6">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac lorem a ligula gravida eleifend sit amet id nunc. Nam quis feugiat nisl, nec iaculis sapien. Quisque vulputate mi in lacus viverra, a congue odio viverra.
                        </p>

                        {/* Author & Date */}
                        <div className="flex items-center mb-6">
                            <img
                                src="https://via.placeholder.com/40"
                                alt="Author"
                                className="w-10 h-10 rounded-full object-cover"
                            />
                            <div className="ml-3">
                                <p className=" font-semibold">John Doe</p>
                                <p className=" text-sm">Published on September 25, 2024</p>
                            </div>
                        </div>

                        {/* Blog Content */}
                        <div className="text-lg leading-relaxed">
                            <p className="mb-4">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac lorem a ligula gravida eleifend sit amet id nunc. Nam quis feugiat nisl, nec iaculis sapien. Quisque vulputate mi in lacus viverra, a congue odio viverra.
                            </p>
                            <p className="mb-4">
                                Praesent quis urna in nulla viverra cursus. Phasellus fermentum lectus a ipsum fringilla, at luctus quam faucibus. Nullam convallis urna at felis posuere vestibulum. Duis tincidunt dolor a tincidunt viverra. Aenean volutpat mollis mauris.
                            </p>
                            <blockquote className="border-l-4 border-slate-500 pl-4 italic mb-4">
                                "React and Tailwind CSS allow you to build modern, fast, and beautiful interfaces with ease."
                            </blockquote>
                            <p className="mb-4">
                                Curabitur et lacus sit amet purus pharetra fermentum. Duis ac justo dolor. Aliquam pharetra facilisis libero, eget aliquam lacus lacinia sed.
                            </p>
                        </div>

                        {/* Tags */}
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold  mb-2">Tags</h3>
                            <div className="space-x-3">
                                <span className="inline-block bg-gray-200 px-3 py-1 rounded-full text-sm">React</span>
                                <span className="inline-block bg-gray-200 px-3 py-1 rounded-full text-sm">Tailwind CSS</span>
                                <span className="inline-block bg-gray-200 px-3 py-1 rounded-full text-sm">JavaScript</span>
                            </div>
                        </div>

                        {/* Comments */}
                        <div className="mt-12">
                            <h3 className="text-xl font-semibold mb-4">Comments</h3>
                            {/* Comment Example */}
                            <div className="mb-6">
                                <div className="flex items-start">
                                    <img
                                        src="https://via.placeholder.com/40"
                                        alt="Commenter"
                                        className="w-10 h-10 rounded-full object-cover"
                                    />
                                    <div className="ml-3">
                                        <p className="font-semibold">Jane Smith</p>
                                        <p className="text-sm">2 hours ago</p>
                                        <p className="mt-2">
                                            Great post! I really enjoyed learning about how React and Tailwind CSS work together.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* Add more comments as needed */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogPage;
