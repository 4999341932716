import React from 'react';

const ProjectCard = ({ name, description, imageUrl }) => (
  <div className="bg-white shadow-md rounded-lg overflow-hidden font-serif">
    <img src={imageUrl} alt={name} className="w-full h-48 object-cover" />
    <div className="p-4">
      <h3 className="text-xl font-serif mb-2">{name}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
      <a href="#" className="text-blue-600 hover:underline">Learn more</a>
    </div>
  </div>
);

const Project = () => {
  const projects = [
    {
      name: "TechInnovate",
      description: "Pioneering software solutions for next-generation applications.",
      imageUrl: "/image/Anubhav.jpg"
    },
    {
      name: "GreenEnergy",
      description: "Developing sustainable power technologies for a cleaner future.",
      imageUrl: "/image/Lucky.jpg"
    },
    {
      name: "HealthPlus",
      description: "Creating advanced medical devices to improve patient care.",
      imageUrl: "/image/harshit.jpeg"
    },
    {
        name: "TechInnovate",
        description: "Pioneering software solutions for next-generation applications.",
        imageUrl: "/image/Anubhav.jpg"
      },
      {
        name: "GreenEnergy",
        description: "Developing sustainable power technologies for a cleaner future.",
        imageUrl: "/image/Lucky.jpg"
      },
      {
        name: "HealthPlus",
        description: "Creating advanced medical devices to improve patient care.",
        imageUrl: "/image/harshit.jpeg"
      },
    
  ];

  return (
    <div className="container mx-auto mt-8 px-4 font-serif">
      <h2 className="text-3xl mb-4">Our Projects : </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </div>
  );
};

export default Project;