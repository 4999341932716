import React from 'react';

const TeamMember = ({ name, position, bio, imageUrl }) => (
  <div className="bg-white shadow-md rounded-lg overflow-hidden text-center font-serif">
    <img src={imageUrl} alt={name} className="w-40 h-40 rounded-full mx-auto mt-4" />
    <div className="p-4">
      <h3 className="text-xl mb-2">{name}</h3>
      <p className="mb-4">{position}</p>
      <p className="text-md">{bio}</p>
    </div>
  </div>
);

const Team = () => {
  const teamMembers = [
    {
      name: "John Doe",
      position: "CEO",
      bio: "With over 20 years of experience in tech leadership, John drives Seemarise's strategic vision.",
      imageUrl: "/image/Anubhav.jpg"
    },
    {
      name: "Jane Smith",
      position: "CTO",
      bio: "Jane's innovative approach to technology has been key to Seemarise's cutting-edge products.",
      imageUrl: "/image/Lucky.jpg"
    },
    {
      name: "Mike Johnson",
      position: "COO",
      bio: "Mike ensures smooth operations across all of Seemarise's subsidiaries and projects.",
      imageUrl: "/image/harshit.jpeg"
    }
  ];

  return (
    <div className="container mx-auto mt-8 px-4">
      <h2 className="text-3xl font-serif mb-4">Our Team :</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {teamMembers.map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </div>
    </div>
  );
};

export default Team;